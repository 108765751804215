import React, { Component } from 'react';
//import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import { Trans } from 'react-i18next';
import { AuthorizedUser, BrandData } from "collaboration-tool-core";

class Navbar extends Component {


  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  userLogOut() {
    AuthorizedUser.resetAuthorizedUser();
    window.location.href = '/Ctool/login';
  }

  getLogoPath() {
    let logoName = BrandData.getLogo();
    if (logoName) {
      return require("../../assets/images/" + logoName).default
    } else {
      return require("../../assets/images/logo.svg").default
    }
  }

  render() {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper nav-bar-additional d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/Ctool/tasks"><img style={{ width: "150px" }} src={this.getLogoPath()}
            alt="logo" /></Link>
          {AuthorizedUser.getIsAdministrator() &&
            <Link className="digital-back-button" to="/digital">
              <span className='digital-back-text-green' >{'Digital'}</span>
              <span className='digital-back-text-black' >{' > Administration Panel'}</span>
            </Link>
          }
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
                <i id="LogoutButton" onClick={() => this.userLogOut()} className="mdi mdi-power Nav-LogoutButton"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;