import React from "react";
import { Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, isPermitted, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            isPermitted ? <Component {...props} /> :
                (
                    <div className="ProtectedRoute-Permission">
                        <h3>You Don't Have Permission To Access The Resource</h3>
                    </div>
                )
        )} />
    );
};

export default ProtectedRoute;