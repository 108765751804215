import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import { AuthorizedUser, FeaturesCode, BrandData } from "collaboration-tool-core";
import ProtectedRoute from "./ProtectedRoute";

const TasksList = lazy(() => import("collaboration-tool-tasks-list"));
const UsersList = lazy(() => import("./user/user-list/UserList"));
const TaskTypeList = lazy(() => import("./task-type/taskType-list/TaskTypeList"));
const WorkflowsList = lazy(() => import("./workflow/workflow-list/WorkflowsList"));
const CreateEditWorkflow = lazy(() => import("./workflow/create-edit-workflow/CreateEditWorkflow"));

const ProcessInstancesList = lazy(() => import("./workflow/process-Instance-list/ProcessInstancesList"));
const ProcessInstanceTrack = lazy(() => import("./workflow/process-instance-track/ProcessInstanceTrack"));

const PartnerTypeList = lazy(() => import("./Permissions/partnerType-list/PartnerTypeList"));
const FeaturesPermissionList = lazy(() => import("./features-permission/features-permission-list/FeaturesPermissionList"));
const AuditLogList = lazy(() => import("./audit-log/audit-log-list/AuditLogList"));
const NLogConfigsList = lazy(() => import("./nlog-configs/NLogConfigsList"));

const Login = lazy(() => import("./login/Login"));
const ForgotPasswordPage = lazy(() => import("./login/ForgotPassword"));
const ResetForgottenPassword = lazy(() => import("./login/ResetForgottenPassword"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const ObjectFieldList = lazy(() => import("./customization/objectField-list/ObjectFieldList"));

const ShipmentList = lazy(() => import("./digital/components/shipments/list/ShipmentList"));
const ShipmentDetails = lazy(() => import("./digital/components/single-shipment/details/ShipmentDetails"));

const Billing = lazy(() => import("./digital/components/billing"));

const DigitalTasksList = lazy(() => import("./digital/components/tasks/list/DigitalTasksList"));
const TaskDetails = lazy(() => import("./digital/components/single-task/details/TaskDetails"));

class AppRoutes extends Component {

  getCurrentLocationPath() {
    return window?.location?.pathname?.toString()?.toLowerCase();
  }

  isCurrentLocationPath(path) {
    let currentLocationPath = this.getCurrentLocationPath();
    return currentLocationPath === path?.toString()?.toLowerCase();
  }

  isCurrentLocationPathIncludes(path) {
    let currentLocationPath = this.getCurrentLocationPath();
    return currentLocationPath?.includes(path?.toString()?.toLowerCase());
  }

  isCurrentLocationPathStartsWith(path) {
    let currentLocationPath = this.getCurrentLocationPath();
    return currentLocationPath?.startsWith(path?.toString()?.toLowerCase());
  }

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>

          {/* If the user UnAuthorized and not in Login/Forgetpassword pages and he in Ctool path, 
              Then he will be redirect to Ctool Login page */}
          {AuthorizedUser.shouldValidateToken()
            && !this.isCurrentLocationPath("/Ctool/login") && !this.isCurrentLocationPath("/Ctool/forgotPassword")
            && this.isCurrentLocationPathIncludes("/Ctool")
            && <Redirect to={"/Ctool/login"} />
          }

          {/* If the user UnAuthorized and not in Login/Forgetpassword pages and he in Digital path, 
              Then he will be redirect to Digital Login page */}
          {AuthorizedUser.shouldValidateToken()
            && !this.isCurrentLocationPath("/Digital/login")
            && !this.isCurrentLocationPath("/Digital/forgotPassword")
            && !this.isCurrentLocationPathStartsWith("/Digital/resetForgotPassword")
            && this.isCurrentLocationPathIncludes("/Digital")
            && <Redirect to={"/Digital/login"} />
          }

          {/* If the user Authorized and in Login/Forgetpassword pages and he in Digital path, 
              Then he will be redirect to Digital Home(shipments) page */}
          {!AuthorizedUser.shouldValidateToken() && !BrandData.isICLTenant() &&
            (this.isCurrentLocationPath("/Digital/login") ||
              this.isCurrentLocationPath("/Digital/forgotPassword") ||
              this.isCurrentLocationPathStartsWith("/Digital/resetForgotPassword"))
            && <Redirect to={"/Digital/shipments"} />
          }

          {!AuthorizedUser.shouldValidateToken() && BrandData.isICLTenant() &&
            (this.isCurrentLocationPath("/Digital/login") ||
              this.isCurrentLocationPath("/Digital/forgotPassword") ||
              this.isCurrentLocationPathStartsWith("/Digital/resetForgotPassword"))
            && <Redirect to={"/Digital/tasks"} />
          }

          {/* If the user Authorized and in Login/Forgetpassword pages and he in Ctool path, 
              Then he will be redirect to Ctool Home(tasks) page */}
          {!AuthorizedUser.shouldValidateToken() &&
            (this.isCurrentLocationPath("/Ctool/login") || this.isCurrentLocationPath("/Ctool/forgotPassword")) &&
            <Redirect to={"/Ctool/tasks"} />
          }

          {/* the default Route is  Digital shipments or Digital login (in case UnAuthorized) */}
          {!AuthorizedUser.shouldValidateToken() && (this.isCurrentLocationPath("/")) && BrandData.isICLTenant() &&
            <Redirect to={"/Ctool/login"} />
          }

          {!AuthorizedUser.shouldValidateToken() && (this.isCurrentLocationPath("/")) && !BrandData.isICLTenant() &&
            <Redirect to={"/Digital/login"} />
          }

          {/* Digital Registered Routes*/}

          {!BrandData.isICLTenant() &&
            <Route exact path="/Digital/shipments" component={ShipmentList} />
          }

          {!BrandData.isICLTenant() &&
            <Route exact path="/Digital/shipments/:id" component={ShipmentDetails} />
          }

          {!BrandData.isICLTenant() &&
            <Route exact path="/Digital/billing" component={Billing} />
          }

          <Route exact path="/Digital/login" component={Login} />
          <Route exact path="/Digital/forgotPassword" component={ForgotPasswordPage} />
          <Route exact path="/Digital/resetForgotPassword" component={ResetForgottenPassword} />
          <Route path="/Digital/error-pages/error-404" component={Error404} />
          <Route path="/Digital/error-pages/error-500" component={Error500} />
          <Route exact path="/Digital/tasks" component={DigitalTasksList} />
          <Route exact path="/Digital/tasks/:id" component={TaskDetails} />

          {/* Ctool Registered Routes*/}

          <ProtectedRoute exact path="/Ctool/tasks"
            component={() => <TasksList logitudeAuthentication={null} enableUncLink={false} uncLinkClickCallback={null} />}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Tasks)} />

          <ProtectedRoute exact path="/Ctool/settings/tasktypes"
            component={TaskTypeList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.TaskTypes)} />

          <ProtectedRoute exact path="/Ctool/settings/users"
            component={UsersList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Users)} />

          <ProtectedRoute exact path="/Ctool/settings/objectfields"
            component={ObjectFieldList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Customization)} />

          <ProtectedRoute exact path="/Ctool/settings/workflows"
            component={WorkflowsList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Workflows)} />

          <ProtectedRoute exact path="/Ctool/settings/workflows/create"
            component={CreateEditWorkflow}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Workflows)} />

          <ProtectedRoute exact path="/Ctool/settings/workflows/edit/:id"
            component={CreateEditWorkflow}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Workflows)} />

          <ProtectedRoute exact path="/Ctool/settings/processinstances"
            component={ProcessInstancesList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.ProcessInstances)} />

          <ProtectedRoute exact path="/Ctool/settings/processinstances/:id"
            component={ProcessInstanceTrack}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.ProcessInstances)} />

          <ProtectedRoute exact path="/Ctool/settings/PartnerTypeList"
            component={PartnerTypeList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.FieldPermissions)} />

          <ProtectedRoute exact path="/Ctool/settings/FeaturesPermissionList"
            component={FeaturesPermissionList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.FeaturePermissions)} />

          <ProtectedRoute exact path="/Ctool/settings/AuditLogs"
            component={AuditLogList}
            isPermitted={AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.AuditLogs)} />

          <ProtectedRoute exact path="/Ctool/settings/NLogConfigs"
            component={NLogConfigsList}
            isPermitted={AuthorizedUser.getEmail() && AuthorizedUser.getEmail().toLowerCase() == 'bddtest@ctool.com'} />


          <Route exact path="/Ctool/login" component={Login} />
          <Route exact path="/Ctool/forgotPassword" component={ForgotPasswordPage} />

          <Route path="/Ctool/error-pages/error-404" component={Error404} />
          <Route path="/Ctool/error-pages/error-500" component={Error500} />

          {/* Unknown Routes after "Digital/" path will be redirect to Digital Login page or Digital Home(shipments) in case user Authorized */}
          {this.isCurrentLocationPathIncludes("/Digital") && <Redirect to="/Digital/login" />}

          {/* Unknown Routes after "Ctool/" path will be redirect to Ctool Login page or Ctool Home(tasks) in case user Authorized */}
          {this.isCurrentLocationPathIncludes("/Ctool") && <Redirect to="/Ctool/login" />}

          {/* Unknown Routes will be redirect to Digital Login page or Digital Home(shipments) in case user Authorized */}
          {/* Beacuse Digital is the default Route */}

          {!BrandData.isICLTenant() &&
            <Redirect to={"/Digital/login"} />
          }

          {BrandData.isICLTenant() &&
            <Redirect to={"/Ctool/login"} />
          }

        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;