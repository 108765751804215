import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { AuthorizedUser, FeaturesCode } from "collaboration-tool-core";
import { Tooltip } from "collaboration-tool-core";

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },

      { path: '/Ctool/tasks', state: 'tasksMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }



  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li style={{ background: "white", cursor: "default" }} className="nav-item nav-profile">
            <div className="nav-link">
              <div className="nav-profile-image">
                <img src={require("../../assets/images/faces/face1.jpg").default} alt="profile" />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text">
                <span className="user-name-title font-weight-bold">{AuthorizedUser.getFirstName()}</span>
                {this.getTenantTitleElement()}
              </div>
            </div>
          </li>
          <ul className="nav">
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Tasks) &&
              <li className={this.isPathActive('/Ctool/tasks') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/tasks') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/tasks"><Trans><span id="TasksTab" className="menu-title" style={{ fontSize: "18px" }}>Tasks</span></Trans></Link>
              </li>
            }
          </ul>
          {
            AuthorizedUser.isPartnerTypeHasSettingsFeaturePermission() &&
            <span style={{ paddingLeft: "2.25rem", paddingBottom: "0.5rem" }} className="menu-title">Settings</span>
          }
          <ul className="nav">
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.TaskTypes) &&
              <li className={this.isPathActive('/Ctool/settings/tasktypes') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/tasktypes') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/tasktypes">
                  <Trans><span id="TaskTypesTab" style={{ paddingLeft: "2.25rem" }} className="menu-title">Task Types</span></Trans>
                </Link>
              </li>
            }
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Users) &&
              <li className={this.isPathActive('/Ctool/settings/users') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/users') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/users">
                  <Trans><span id="UsersTab" style={{ paddingLeft: "2.25rem" }} className="menu-title">Users</span></Trans>
                </Link>
              </li>
            }
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Customization) &&
              <li className={this.isPathActive('/Ctool/settings/objectfields') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/objectfields') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/objectfields">
                  <Trans><span id="CustomizationTab" style={{ paddingLeft: "2.25rem" }} className="menu-title">Customization</span></Trans>
                </Link>
              </li>
            }
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.Workflows) &&
              <li className={this.isPathActive('/Ctool/settings/workflows') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/workflows') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/workflows">
                  <Trans><span id="WorkflowsTab" style={{ paddingLeft: "2.25rem" }} className="menu-title">Workflows</span></Trans>
                </Link>
              </li>
            }
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.ProcessInstances) &&
              <li className={this.isPathActive('/Ctool/settings/processinstances') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/processinstances') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/processinstances">
                  <Trans><span style={{ paddingLeft: "2.25rem" }} className="menu-title">Process Instances</span></Trans>
                </Link>
              </li>
            }
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.FieldPermissions) &&
              <li className={this.isPathActive('/Ctool/settings/PartnerTypeList') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/PartnerTypeList') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/PartnerTypeList">
                  <Trans><span id="FieldPermissionsTab" style={{ paddingLeft: "2.25rem" }} className="menu-title">Field Permissions</span></Trans>
                </Link>
              </li>
            }
            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.FeaturePermissions) &&
              <li className={this.isPathActive('/Ctool/settings/FeaturesPermissionList') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/FeaturesPermissionList') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/FeaturesPermissionList">
                  <Trans><span style={{ paddingLeft: "2.25rem" }} className="menu-title">Role and Permissions</span></Trans>
                </Link>
              </li>
            }

            {
              AuthorizedUser.getPartnerTypeFeaturePermission(FeaturesCode.AuditLogs) &&
              <li className={this.isPathActive('/Ctool/settings/AuditLogs') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/AuditLogs') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/AuditLogs">
                  <Trans><span style={{ paddingLeft: "2.25rem" }} className="menu-title">Audit Logs</span></Trans>
                </Link>
              </li>
            }

            {
              AuthorizedUser.getEmail() && AuthorizedUser.getEmail().toLowerCase() == 'bddtest@ctool.com' &&
              <li className={this.isPathActive('/Ctool/settings/NLogConfigs') ? 'nav-item active' : 'nav-item'}>
                <Link style={{ padding: "0.9rem 0" }} className={this.isPathActive('/Ctool/settings/NLogConfigs') ? 'nav-link active' : 'nav-link'}
                  to="/Ctool/settings/NLogConfigs">
                  <Trans><span style={{ paddingLeft: "2.25rem" }} className="menu-title">NLog Configs</span></Trans>
                </Link>
              </li>
            }
          </ul>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  getTenantTitleElement() {
    let tenantCompanyName = AuthorizedUser.getCompanyName();
    if (tenantCompanyName) {
      return (
        <React.Fragment>
          <span className="tenant-title">{tenantCompanyName}</span>
          {tenantCompanyName.length > 16 &&
            <Tooltip target=".tenant-title" className="tenant-title-tooltip" position="top" content={tenantCompanyName} />
          }
        </React.Fragment>
      );
    }
    return null;
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);