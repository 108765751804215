import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import { withTranslation } from "react-i18next";
import { AuthenticateService, AuthorizedUser, SessionInfo, LogitudeTokensService,BrandService, BrandData} from "collaboration-tool-core";
import FullScreenLoader from "./components/full-screen-loader/FullScreenLoader";

class App extends Component {

  state = {
    validateTokenCompleted: false,
    requestLogitudeTokensCompleted: false,
    requestBrandCompleted: false,
  }

  componentDidMount() {
    this.validateToken();
    this.setDomainBrand();
  }

  validateToken() {
    const authenticateService = new AuthenticateService();
    let shouldValidateToken = AuthorizedUser.shouldValidateToken();
    if (shouldValidateToken) {
      let authorizedUser = AuthorizedUser.getAuthorizedUser();
      if (authorizedUser !== null) {
        let token = AuthorizedUser.getToken();
        authenticateService.isValidToken(token).then(isValidToken => {
          if (isValidToken) {
            this.completeValidateToken(true, token);
          } else {
            this.completeValidateToken(false, null);
          }
        });
      } else {
        this.completeValidateToken(false, null);
      }
    } else {
      this.completeValidateToken(true, AuthorizedUser.getToken());
    }
  }

  setDomainBrand() {
    const brandService = new BrandService();
    let URL = window.location.host;
    if (URL) {
      brandService.getBrandData(URL).then(response => {
        if (response) {
          BrandData.setBrandData(response)
          this.setState({ requestBrandCompleted: true });
        }else{
          BrandData.resetBrand()
          this.setState({ requestBrandCompleted: true });
        }
      })
    }
  }

  completeValidateToken(isUserAuthorized, token) {
    SessionInfo.IsUserAuthorized = isUserAuthorized;
    SessionInfo.AuthorizedUserToken = token;
    SessionInfo.IsLogitudeAuthentication = false;
    this.setState({ validateTokenCompleted: true });
    if (isUserAuthorized) {
      this.requestLogitudeTokens(true);
      setInterval(() => {
        this.requestLogitudeTokens();
      }, 3600000);
    } else {
      this.setState({ requestLogitudeTokensCompleted: true });
    }
    this.onRouteChanged();
  }

  requestLogitudeTokens(isFirstTime = false) {
    const logitudeTokensService = new LogitudeTokensService();
    logitudeTokensService.getTokens().then(logitudeTokens => {
      let logitudeTokensJson = JSON.stringify(logitudeTokens);
      localStorage.setItem("LogitudeTokens", logitudeTokensJson);
      this.setRequestLogitudeTokensCompleted(isFirstTime);
    }).catch(error => {
      console.log(error);
      this.setRequestLogitudeTokensCompleted(isFirstTime);
    });
  }

  setRequestLogitudeTokensCompleted(isFirstTime) {
    if (isFirstTime) {
      this.setState({ requestLogitudeTokensCompleted: true });
    }
  }

  getFullPagePaths() {
    return [
      '/user-pages/login-1',
      '/user-pages/register-1',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
      '/digital/',
      '/forgotpassword',
      '/login'
    ]
  };

  getCurrentLocationPath() {
    return this.props?.location?.pathname?.toString()?.toLowerCase();
  }

  isCurrentLocationPathIncludeFullPagePath() {
    let currentLocationPath = this.getCurrentLocationPath();
    if (currentLocationPath && this.getFullPagePaths().filter(p => currentLocationPath.includes(p?.toLowerCase())).length > 0) {
      return true;
    }
    return false;
  }

  isCurrentLocationPath(path) {
    let currentLocationPath = this.getCurrentLocationPath();
    return currentLocationPath === path?.toString()?.toLowerCase();
  }

  isCurrentLocationPathIncludes(path) {
    let currentLocationPath = this.getCurrentLocationPath();
    return currentLocationPath?.includes(path?.toString()?.toLowerCase());
  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';

    return (
      <React.Fragment>
        {this.state.validateTokenCompleted && this.state.requestLogitudeTokensCompleted && this.state.requestBrandCompleted && 
          <React.Fragment>
            {SessionInfo.IsUserAuthorized &&
              <div className="container-scroller">
                {navbarComponent}
                <div className={`container-fluid page-body-wrapper ${this.isCurrentLocationPathIncludeFullPagePath() ? 'full-page-wrapper' : ''}`}>
                  {sidebarComponent}
                  <div className="main-panel">
                    <div className={this.isCurrentLocationPathIncludeFullPagePath() ? "content-wrapper p-p-0" : "content-wrapper"}>
                      <div className="h-100">
                        <AppRoutes />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {!SessionInfo.IsUserAuthorized &&
              <AppRoutes />
            }
          </React.Fragment>
        }

        {(!this.state.validateTokenCompleted || !this.state.requestLogitudeTokensCompleted || !this.state.requestBrandCompleted) &&
          <FullScreenLoader />
        }
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
      if ((this.isCurrentLocationPath("/Digital/login") || this.isCurrentLocationPath("/Ctool/login")) && AuthorizedUser.shouldValidateToken()) {
        window.location.reload();
      }
    }
  }

  onRouteChanged() {
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if (this.isCurrentLocationPath('/layout/RtlLayout')) {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);

    let isCurrentLocationPathIncludeFullPagePath = this.isCurrentLocationPathIncludeFullPagePath();

    this.setState({
      isFullPageLayout: isCurrentLocationPathIncludeFullPagePath
    });

    let pageBodyWrapper = document.querySelector('.page-body-wrapper');
    if (pageBodyWrapper !== null) {
      let fullPageWrapperClass = "full-page-wrapper";
      if (isCurrentLocationPathIncludeFullPagePath) {
        pageBodyWrapper.classList.add(fullPageWrapperClass);
      } else {
        pageBodyWrapper.classList.remove(fullPageWrapperClass);
      }
    }

    // const fullPageLayoutRoutes = this.getFullPagePaths();

    // for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
    //   if (this.isCurrentLocationPathIncludes(fullPageLayoutRoutes[i])) {
    //     this.setState({
    //       isFullPageLayout: true
    //     });
    //     let pageBodyWrapper = document.querySelector('.page-body-wrapper');
    //     if (pageBodyWrapper !== null) {
    //       pageBodyWrapper.classList.add('full-page-wrapper');
    //     }
    //     break;
    //   } else {
    //     this.setState({
    //       isFullPageLayout: false
    //     });
    //     let pageBodyWrapper = document.querySelector('.page-body-wrapper');
    //     if (pageBodyWrapper !== null) {
    //       pageBodyWrapper.classList.remove('full-page-wrapper');
    //     }
    //   }
    // }
  }

}

export default withTranslation()(withRouter(App));